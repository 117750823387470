.post-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #b0b0b0 solid;
    padding-bottom: 0.5rem;
}
.post-title {
    font-size: 2rem;
    font-weight: bold;
    align-self: flex-end;
    vertical-align: bottom;
}

.post-date {
    align-self: flex-end;
    font-size: 1.2rem;
    font-weight: lighter;
    color: #b0b0b0;
    font-style: italic;
}

.post-content {
    font-size: 25px;
    border-bottom: 1px solid #b0b0b0;
}

.post-footnotes {
    font-size: 20px;
    padding: 3rem 0;
}

.post-gif {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
}

em {
    color: #D72638;
}