ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    border-bottom: 1px #b0b0b0 solid;
    border-image: linear-gradient(to left, transparent, #b0b0b0) 1;
    transition: 0.5s all;
    padding: 1rem;
}

li:hover {
    background-image: linear-gradient(to left, transparent, lightgray);
}

.entry-title {
    font-size: 40px;
    user-select: none;
}

.entry-title:hover {
    cursor: pointer;
}