html {
  background-color: #fdfbfb;
}

::selection {
  background: #111111;
  color: #fdfbfb;
}

body {
  margin: 0;
  color: #333333;
  font-family: "Gowun Batang", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.App-header {
  text-align: center;
  padding: 1rem;
  border-bottom: 1px #b0b0b0 solid;
}